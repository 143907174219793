/**
* @file mdm.js
*
* Master file for theme level js.
*/

'use strict';

(function($, Drupal, window, document, undefined) {
  $(document).ready(function() {
    // Do abc yamaha theme level stuff here.
  });
})(jQuery, Drupal, this, this.document);
